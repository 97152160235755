<template>
  <div class="home-box">
    <div class="home-box-title">
      <div
        class="home-box-title-name"
      >{{ timeState }}，{{ $store.state.userInfo.name }}</div>
      <div class="home-box-title-msg">做最值得信赖的工程机械行业大数据风险管理服务商~</div>
    </div>
    <img class="home-box-bg" src="@/assets/images/home-bg.jpg" alt srcset />
  </div>
</template>

<script>
import { getTimeState } from "@/utils/formatDate";
export default {
  data() {
    return {
      timeState: ''
    };
  },
  created() {
    this.timeState = getTimeState();
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.home-box {
  padding: 12px;
  height: 100%;
  overflow: auto;
  &-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    padding: 14px;
    margin-bottom: 12px;
    background-color: #4278c9;
    background-image: url('../../assets/images/home-title.png');
    border-radius: 8px;
    background-size: 100% 100%;
    color: #fff;
    overflow: hidden;
    &-msg {
      font-size: 14px;
    }
    &-name {
      font-size: 20px;
    }
  }
  &-bg {
    width: 100%;
  }
}
</style>